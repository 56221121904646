import { cloneDeep } from 'lodash';
import type {
  AnalysisPortfolioComparisonTypeEnum,
  CategoryGroupOption,
  Fund,
  Portfolio,
  PortfolioCompare,
  PrivateFund,
  PrivatePortfolioNode,
} from 'venn-api';
import { logExceptionIntoSentry, logMessageToSentry } from '../error-logging';
import { getPortfolioLevelSelected, selectStrategy, updateNode } from '../portfolio';
import type { AnalysisSubjectType } from './types';

export type AnalysisSubjectSecondaryLabel = 'Master' | 'Last Saved' | 'Optimized' | undefined;

export interface AnalysisSubjectOptions {
  strategyId?: number;
  strategyFund?: Fund;
  secondaryPortfolio?: Portfolio;
  /**
   * `secondaryStrategy` is ONLY used in Studio; in AnalysisPage, `secondaryPortfolio` already has the value of the
   * strategy within the secondary portfolio that corresponds to selected strategy on the primary portfolio.
   * */
  secondaryStrategy?: Portfolio;
  secondaryLabel?: AnalysisSubjectSecondaryLabel;
  categoryPrediction?: CategoryGroupOption;

  /** create a minimal valid analysis subject using just the instrument ID and name. */
  instrumentIdOnly?: boolean;
}

const defaultOptions: AnalysisSubjectOptions = {
  strategyId: undefined,
  strategyFund: undefined,
  secondaryPortfolio: undefined,
  secondaryStrategy: undefined,
  secondaryLabel: undefined,
  categoryPrediction: undefined,
};

const draftPortfolio: Portfolio = {
  closingAllocationsTs: [],
  historical: false,
  children: [],
  demo: false,
  draft: true,
  id: -1,
  master: false,
  name: 'Draft Portfolio',
};

/**
 * Represents an Analysis Subject, which is an item being analysed.
 * Please never mutate this object itself, always re-create a new one for any change.
 */
class AnalysisSubject {
  private _privateFund?: PrivateFund;

  private _privatePortfolio?: PrivatePortfolioNode;

  private _fund?: Fund;

  private _portfolio?: Portfolio;

  private _strategy?: Portfolio;

  private _type: AnalysisSubjectType;

  private _strategyType?: AnalysisSubjectType;

  private _strategyId?: number;

  private _master: boolean;

  private _portfolioLevelSelected: number | undefined;

  private _hasProxy: boolean;

  private _fundCategoryPrediction: CategoryGroupOption | undefined;

  /**
   * For secondary analysis subject data.
   * Currently, we can only use the Master Portfolio or a historical version of the portfolio, but in the future
   * we may allow arbitrary comparison.
   */
  private _secondaryPortfolio: Portfolio | undefined;

  private _secondaryStrategy: Portfolio | undefined;

  private _secondaryLabel: AnalysisSubjectSecondaryLabel;

  private _instrumentIdOnly: boolean;

  constructor(
    item: Fund | Portfolio | PrivateFund | PrivatePortfolioNode,
    superType: AnalysisSubjectType,
    options?: AnalysisSubjectOptions,
  ) {
    const { strategyId, strategyFund, secondaryPortfolio, secondaryStrategy, secondaryLabel, categoryPrediction } =
      options || defaultOptions;

    this._instrumentIdOnly = options?.instrumentIdOnly ?? false;

    this._type = superType;
    if (!item) {
      logExceptionIntoSentry(new Error('Tried to create AnalysisSubject with undefined item'));
      return;
    }

    if (superType === 'private-portfolio') {
      this._privatePortfolio = Object.freeze(item as PrivatePortfolioNode);
    }
    if (superType === 'portfolio') {
      // We want to make 100% sure that portfolio is not mutated.
      this._portfolio = Object.freeze(item as Portfolio);
      this._strategy = strategyId ? selectStrategy(strategyId, this._portfolio) ?? this._portfolio : this._portfolio;
      this._strategyId = this._strategy.id;
      this._strategyType = this._strategy.fund === undefined ? 'portfolio' : 'investment';
      if (this._strategyType === 'investment' && strategyFund !== undefined) {
        this._fund = strategyFund;
        this._master = false;
        this._hasProxy = !!this._fund.proxyId;
        this._fundCategoryPrediction = categoryPrediction;
      } else {
        this._master = this._strategy.master || false;
        this._portfolioLevelSelected = getPortfolioLevelSelected(this._portfolio, strategyId) || 0;
        this._hasProxy = false;
      }

      if (secondaryPortfolio) {
        this._secondaryPortfolio = secondaryPortfolio;
        this._secondaryStrategy = secondaryStrategy;
        this._secondaryLabel = secondaryLabel;
      }
    }

    if (superType === 'private-investment') {
      // We want to make 100% sure that fund is not mutated.
      this._privateFund = Object.freeze(item as PrivateFund);
      this._master = false;
      this._hasProxy = !!this._fund?.proxyId;
      this._fundCategoryPrediction = categoryPrediction;
    }
    if (superType === 'investment') {
      // We want to make 100% sure that fund is not mutated.
      this._fund = Object.freeze(item as Fund);
      this._master = false;
      this._hasProxy = !!this._fund?.proxyId;
      this._fundCategoryPrediction = categoryPrediction;
    }
  }

  public get portfolio(): Portfolio | undefined {
    if (this._instrumentIdOnly) {
      return undefined;
    }
    return this._portfolio;
  }

  public get privatePortfolio(): PrivatePortfolioNode | undefined {
    if (this._instrumentIdOnly) {
      return undefined;
    }
    return this._privatePortfolio;
  }

  public get strategy(): Portfolio | undefined {
    if (this._instrumentIdOnly) {
      return undefined;
    }
    return this._strategy;
  }

  public get fund(): Fund | undefined {
    if (this._instrumentIdOnly) {
      return undefined;
    }
    return this._fund;
  }

  public get privateFund(): PrivateFund | undefined {
    if (this._instrumentIdOnly) {
      return undefined;
    }
    return this._privateFund;
  }

  /**
   * Returns the type of the object set as subject: if we're analyzing an investment _within a portfolio_,
   * it will return the "PORTFOLIO" type.
   */
  public get superType(): AnalysisSubjectType {
    return this._type;
  }

  /**
   * Returns the type of the _actual object analyzed_: if we're analyzing an investment _within a portfolio_,
   * it will return the "INVESTMENT" type.
   */
  public get type(): AnalysisSubjectType {
    return this._strategyType === undefined ? this._type : this._strategyType;
  }

  public get isInvestmentInPortfolio(): boolean {
    return this._type === 'portfolio' && this._strategyType === 'investment';
  }

  public get isStrategyInPortfolio(): boolean {
    return this._strategyType === 'portfolio' && this.superId !== this.strategyId;
  }

  public get portfolioLevelSelected(): number | undefined {
    return this._portfolioLevelSelected;
  }

  public get strategyId(): number | undefined {
    return this._strategyId;
  }

  /** Returns the object set as subject: if we're analyzing an investment _within a portfolio_, it will return the PORTFOLIO id. */
  public get superItem(): Fund | Portfolio | PrivateFund | PrivatePortfolioNode {
    const result =
      this.superType === 'private-investment' || (this.superType === 'investment' && this.private)
        ? this.privateFund
        : this.superType === 'private-portfolio' || (this.superType === 'portfolio' && this.private)
          ? this.privatePortfolio
          : this.superType === 'portfolio'
            ? this.portfolio
            : this.fund;
    if (result === undefined) {
      logExceptionIntoSentry(new Error(`undefined superItem() [superType = ${this.superType}]`));
      return draftPortfolio;
    }

    return result;
  }

  /** Returns the _actual object analyzed_: if we're analyzing an investment _within a portfolio_, it will return the INVESTMENT id. */
  public get item(): Fund | Portfolio | PrivateFund | PrivatePortfolioNode {
    const result =
      this.type === 'private-portfolio'
        ? this.privatePortfolio
        : this.type === 'private-investment'
          ? this.privateFund
          : this.type === 'portfolio'
            ? this.portfolio
            : this.fund;
    if (!result) {
      logExceptionIntoSentry(new Error(`undefined item() [superType = ${this.superType}]`));
      return draftPortfolio;
    }

    return result;
  }

  public get master(): boolean {
    return this._master;
  }

  public get userUploaded(): boolean {
    if (this._type === 'investment' && this._fund !== undefined) {
      return this._fund.userUploaded;
    }
    if (this._strategyType === 'investment' && this._fund !== undefined) {
      return this._fund.userUploaded;
    }
    if (this._type === 'private-investment' && this._privateFund !== undefined) {
      return this._privateFund.userUploaded;
    }
    if (this._strategyType === 'private-investment' && this._privateFund !== undefined) {
      return this._privateFund.userUploaded;
    }
    return false;
  }

  public get hasProxy(): boolean {
    return this._hasProxy;
  }

  public get hasBenchmark(): boolean {
    return !!this.benchmarks.find((b) => b.primary);
  }

  /** Gets the name for this particular analysis subject, or empty string if the backend failed to provide a name. */
  public get name(): string {
    const result =
      this.superType === 'private-investment' || (this.superType === 'investment' && this.private)
        ? this._privateFund?.name
        : this.superType === 'private-portfolio' || (this.superType === 'portfolio' && this.private)
          ? this._privatePortfolio?.name
          : this.superType === 'portfolio'
            ? this._strategy?.name
            : this._fund?.name;
    // TODO: should we log the case where all checked names are undefined/null?
    return result || '';
  }

  /** Even if strategy is selected, it still shows full portfolio's name. */
  public get originalName(): string {
    const result =
      this.superType === 'private-investment' || (this.superType === 'investment' && this.private)
        ? this._privateFund?.name
        : this.superType === 'private-portfolio' || (this.superType === 'portfolio' && this.private)
          ? this._privatePortfolio?.name
          : this.superType === 'portfolio'
            ? this._portfolio?.name
            : this._fund?.name;
    if (result === undefined) {
      logMessageToSentry('missing originalName()');
      return '';
    }

    return result;
  }

  /**
   * Returns the id of the object set as subject: if we're analyzing an investment _within a portfolio_, it will return the PORTFOLIO id. =
   */
  public get superId(): string | number {
    const result =
      this.superType === 'private-investment' || (this.superType === 'investment' && this.private)
        ? this._privateFund?.id
        : this.superType === 'private-portfolio' || (this.superType === 'portfolio' && this.private)
          ? this._privatePortfolio?.id
          : this.superType === 'portfolio'
            ? this._portfolio?.id
            : this._fund?.id;

    if (result === undefined) {
      logMessageToSentry('missing superId()');
      return '';
    }
    return result;
  }

  public get private(): boolean {
    return !!this._privateFund || !!this._privatePortfolio;
  }

  /**
   * Returns the id of the _actual object analyzed_: if we're analyzing an investment _within a portfolio_, it will return the INVESTMENT id.
   */
  public get id(): string | number {
    const result =
      this.superType === 'private-investment' || (this.superType === 'investment' && this.private)
        ? this._privateFund?.id
        : this.superType === 'private-portfolio' || (this.superType === 'portfolio' && this.private)
          ? this._privatePortfolio?.id
          : this.type === 'portfolio'
            ? this._strategyId ?? this._privatePortfolio?.id
            : this.superType === 'portfolio' && this.type === 'investment'
              ? this._strategy?.fund?.id
              : this._fund?.id ?? this._privateFund?.id;
    if (result === undefined) {
      logMessageToSentry(`missing id() on object: ${JSON.stringify(this)}`);
      return '';
    }
    return result;
  }

  public get hasSecondarySubject() {
    return this._secondaryPortfolio !== undefined;
  }

  public get secondarySuperType() {
    return this._secondaryPortfolio !== undefined ? 'portfolio' : undefined;
  }

  public get secondaryType() {
    if (!this._secondaryPortfolio) {
      return undefined;
    }
    return this._secondaryPortfolio.fund !== undefined ? 'investment' : 'portfolio';
  }

  public get secondarySuperId() {
    return this._secondaryPortfolio !== undefined ? this._secondaryPortfolio.id : undefined;
  }

  public get secondaryId() {
    if (!this._secondaryPortfolio) {
      return undefined;
    }
    return this._secondaryPortfolio.fund !== undefined ? this._secondaryPortfolio.fund.id : this._secondaryPortfolio.id;
  }

  public get secondaryLabel() {
    return this._secondaryLabel;
  }

  public get secondaryPortfolioComparisonType(): AnalysisPortfolioComparisonTypeEnum {
    switch (this._secondaryLabel) {
      case 'Last Saved':
        return 'SAVED';
      case 'Master':
        return 'MASTER';
      case 'Optimized':
        return 'OPTIMIZED';
      default:
        return 'NONE';
    }
  }

  public get secondaryPortfolio() {
    return this._secondaryPortfolio;
  }

  public get secondaryStrategy() {
    return this._secondaryStrategy;
  }

  /**
   * Category is analyzable when it has `categoryId`.
   * In most cases when the category is unanalyzable, we want to pretend that the subject doesn't have it.
   * In other cases, the category can be accessed through this.fund.categoryGroup.
   */
  public get categoryGroup(): CategoryGroupOption | undefined {
    if (this.fund?.categoryGroup?.categoryId) {
      return this.fund.categoryGroup;
    }

    if (
      (!this.fund || !this.fund.categoryGroup) &&
      this._fundCategoryPrediction &&
      this._fundCategoryPrediction.categoryId
    ) {
      return this._fundCategoryPrediction;
    }

    return undefined;
  }

  /**
   * Whether the category group returned from `categoryGroup()` was a prediction
   */
  public get isCategoryPredicted(): boolean {
    return !!this.fund && !this.fund.categoryGroup && !!this._fundCategoryPrediction;
  }

  public get categoryPrediction() {
    return this._fundCategoryPrediction ? this._fundCategoryPrediction : undefined;
  }

  public get benchmarks(): PortfolioCompare[] {
    if (this.type === 'portfolio') {
      return this.strategy?.compare ?? [];
    }

    let benchmarks;

    if (this.type === 'investment') {
      benchmarks = this.fund?.investmentBenchmarks;
    } else if (this.type === 'private-investment') {
      benchmarks = this.privateFund?.benchmarks;
    } else if (this.type === 'private-portfolio') {
      benchmarks = this.privatePortfolio?.benchmarks;
    }

    return (benchmarks ?? []).map((benchmark) => ({
      benchmark: benchmark.primary,
      ...benchmark,
    }));
  }

  public get activeBenchmark() {
    // Some Legacy code still use benchmark instead of primary
    return this.benchmarks.find((benchmark) => benchmark.primary || benchmark.benchmark);
  }

  public get activeBenchmarkId() {
    return this.activeBenchmark?.fundId || this.activeBenchmark?.portfolioId;
  }

  public get activeBenchmarkType() {
    return this.activeBenchmark ? (this.activeBenchmark.fundId ? 'investment' : 'portfolio') : '';
  }

  public get activeBenchmarkName() {
    return this.activeBenchmark?.name;
  }

  public getWithUpdatedBenchmarks(newSubject: AnalysisSubject): AnalysisSubject {
    const { item, type, strategyId, strategy, fund } = newSubject;

    // If the subject is a single fund within a portfolio node, `newSubject.fund` already has the up-to-date benchmarks.
    if (this.superType === 'portfolio' && this._portfolio && type === 'investment') {
      return new AnalysisSubject(this._portfolio, 'portfolio', { ...this.getOptionsCopy(), strategyFund: fund });
    }

    // If the subject is a normal portfolio, find the selected strategy, and get the updated benchmarks from
    // `newSubject.strategy.compare`.
    if (this.superType === 'portfolio' && this._portfolio && type === 'portfolio' && strategy) {
      let updatedOldPortfolio = cloneDeep(this._portfolio);
      updatedOldPortfolio = updateNode(
        updatedOldPortfolio,
        strategyId || (item as Portfolio).id,
        (node: Portfolio) => ({
          ...node,
          compare: newSubject.strategy?.compare ?? [],
        }),
      );
      return new AnalysisSubject(updatedOldPortfolio, 'portfolio', this.getOptionsCopy());
    }

    // If returning the current object updated wasn't necessary, just return what was passed as a potential new subject
    return newSubject;
  }

  public get isCompositeBenchmark(): boolean {
    return !!(this._fund && this._fund.assetType === 'BENCHMARK');
  }

  public get isSystemFund() {
    return (
      (this.type === 'investment' || this.type === 'private-investment') &&
      this.fund &&
      this.fund.investmentSource === 'VENN'
    );
  }

  public get isCategory() {
    return !!(this._fund && this._fund.assetType === 'CATEGORY_GROUPING');
  }

  public getOptionsCopy(): AnalysisSubjectOptions {
    return {
      strategyId: this._strategyId,
      strategyFund: this._type === 'portfolio' && this._strategyType === 'investment' ? this._fund : undefined,
      secondaryPortfolio: this._secondaryPortfolio,
      secondaryStrategy: this._secondaryStrategy,
      secondaryLabel: this._secondaryLabel,
      categoryPrediction: this._fundCategoryPrediction,
      instrumentIdOnly: this._instrumentIdOnly,
    };
  }
}

export default AnalysisSubject;
