import type { DeepDiveContent } from './types';
import { getTextThemeProvider } from 'venn-ui-kit';

const RESIDUALIZATION_ARTICLE_URL = getTextThemeProvider().MarcomSiteLinks.RESIDUALIZATION_URL;

export const getDeepDiveContent = (hasNewMstarIndices: boolean): DeepDiveContent => {
  return {
    equity: {
      name: 'Equity',
      category: 'Core Macro',
      title:
        '**Equity** is a **Core Macro Factor** that is designed to have exposure to the long-term economic growth and profitability of companies.',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `The Morningstar Global Markets Net Total Return Local Index     

The factor is designed be in excess of the risk-free rate.`
            : 'A GDP-weighted sum of constituent country equity gross returns from 1971-2001 and the MSCI All Country World Index, currency hedged from 2002.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor does not undergo residualization.'
            : 'The factor is designed to be in excess of the risk-free rate.',
        },
      ],
    },
    'interest-rates': {
      name: 'Interest Rates',
      category: 'Core Macro',
      title:
        '**Interest Rates** is a **Core Macro Factor** that is designed to have exposure to the time value of money (interest rates and inflation risk).',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `*Before November 22, 2001*

A GDP-weighted sum of constituent country interest rate gross returns

*From November 22, 2001*

The Bloomberg Global Treasury 7-10 Year Index, currency hedged

The factor is designed be in excess of the risk-free rate.`
            : 'A GDP-weighted sum of constituent country interest rate gross returns from 1971-2001 and the Bloomberg Global Treasury 7-10 Year Index, 100% Currency Hedged from 2002.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor does not undergo residualization.'
            : 'The factor is designed to be in excess of the risk-free rate.',
        },
      ],
    },
    credit: {
      name: 'Credit',
      category: 'Core Macro',
      title:
        '**Credit** is a **Core Macro Factor** that is designed to have exposure to corporate default and failure-to-pay risks specific to developed market corporate bonds.',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `An equal-weighted combination of the following indices:

*Before August 17, 2000*
- 50% Bloomberg US Corporate Index
- 50% Bloomberg US Corporate High Yield Index
            
*From August 17, 2000*
- 25% Bloomberg US Corporate Index
- 25% Bloomberg US Corporate High Yield Index
- 25% Bloomberg Pan-European Aggregate Corporate Index, currency hedged
- 25% Bloomberg Pan-European High Yield Index, currency hedged

The factor is designed be in excess of the risk-free rate.`
            : `An equal-weighted combination of the following indices:
- *1994-1998*
  - 50% Bloomberg US Corporate Index
  - 50% Bank of America Merrill Lynch High Yield Master II Index
- *1998-2000*
  - 50% Bloomberg US Corporate Index
  - 50% Bloomberg US Corporate High Yield Index
- *from 2000*
  - 25% Bloomberg US Corporate Index
  - 25% Bloomberg US Corporate High Yield Index
  - 25% Bloomberg Pan-European Aggregate Corporate Index, currency hedged
  - 25% Bloomberg Pan-European High Yield Index, currency hedged
      `,
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor is designed to be orthogonal to the Equity and Interest Rates factors.'
            : 'The factor is designed to be orthogonal to the Equity and Interest Rates factors and in excess of the risk-free rate.',
        },
      ],
    },
    commodities: {
      name: 'Commodities',
      category: 'Core Macro',
      title:
        '**Commodities** is a **Core Macro Factor** that is designed to have exposure to changes in prices for hard assets.',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `The Bloomberg Commodity Index Total Return

The factor is designed to be currency hedged and in excess of the risk-free rate.`
            : 'The Bloomberg Commodity Index Total Return.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor is designed to be orthogonal to the Equity and Interest Rates factors.'
            : 'The factor is designed to be orthogonal to the Equity and Interest Rates factors and a global currency basket (Short USD, Long G10 FX ex USD) and in excess of the risk-free rate.',
        },
      ],
    },
    'emerging-markets': {
      name: 'Emerging Markets',
      category: 'Secondary Macro',
      title:
        '**Emerging Markets** is a **Secondary Macro Factor** that is designed to have exposure to the sovereign and economic risks of emerging markets relative to developed markets.',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `Weighted combination of the following indices:
- 33% Bloomberg Emerging Markets USD Aggregate Total Return Index
- 67% Morningstar Emerging Markets USD Net Total Return Index

The factor is designed to be currency hedged and in excess of the risk-free rate.`
            : `An equal-weighted combination of the following indices:
- *1994-2000*
  - 33% Bloomberg Emerging Markets USD Aggregate Total Return Index 
  - 67% MSCI Emerging Markets Index 
- *from 2000*
  - 33% Bloomberg Emerging Markets USD Aggregate Total Return Index 
  - 33% MSCI Emerging Markets Index 
  - 33% MSCI Emerging Markets Currency Index 
`,
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors.'
            : 'The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors and a global currency basket (Short USD, Long G10 FX ex USD) and in excess of the risk-free rate.',
        },
      ],
    },
    'foreign-currency': {
      name: 'Foreign Currency',
      category: 'Secondary Macro',
      title:
        '**Foreign Currency** is a **Secondary Macro Factor** that is designed to have exposure to moves in foreign currency values versus the portfolio’s local currency.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'G10 GDP-weighted basket built with a short position in the local currency and long positions in the other nine currencies.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors.',
        },
      ],
    },
    'local-inflation': {
      name: 'Local Inflation',
      category: 'Secondary Macro',
      title:
        '**Local Inflation** is a **Secondary Macro Factor** that is designed to have exposure to inflation-linked rates relative to fixed nominal rates within the local currency area (only available in USD or GBP).',
      aspects: [
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'USD',
          description:
            'The Bloomberg Inflation Linked 7-10 Year Total Return Index minus Bloomberg Treasury 7-10 Year Total Return Index.',
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'GBP',
          description:
            'The Bloomberg UK Inflation-Adjusted 7-10 Year Total Return Index minus Bloomberg Series-E 7-10 Year Total Return Index.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The factor is designed to be orthogonal to the Equity, Interest Rates, Credit, and Commodities factors.',
        },
      ],
    },
    'local-equity': {
      name: 'Local Equity',
      category: 'Secondary Macro',
      title:
        '**Local Equity** is a **Secondary Macro Factor** that is designed to have exposure to home bias (the tendency to invest in domestic over foreign equity).',
      aspects: [
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'AUD',
          description: hasNewMstarIndices
            ? `The Morningstar Australia Net Total Return Index

The factor is designed be in excess of the local risk-free rate.`
            : "Australia's equity gross returns from 1971-1999 and the MSCI Australia Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'CAD',
          description: hasNewMstarIndices
            ? `The Morningstar Canada Net Total Return Index
          
The factor is designed be in excess of the local risk-free rate.`
            : "Canada's equity gross returns from 1971-1999 and the MSCI Canada Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'CHF',
          description: hasNewMstarIndices
            ? `The Morningstar Switzerland Net Total Return Index
          
The factor is designed be in excess of the local risk-free rate.`
            : "Switzerland's equity gross returns from 1971-1999 and the MSCI Switzerland Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'EUR',
          description: hasNewMstarIndices
            ? `The Morningstar Eurozone Net Total Return Index
            
The factor is designed be in excess of the local risk-free rate.`
            : "The European Union's equity gross returns from 1971-1999 and the MSCI EMU Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'GBP',
          description: hasNewMstarIndices
            ? `The Morningstar UK Net Total Return Index
          
The factor is designed be in excess of the local risk-free rate.`
            : "The United Kingdom's equity gross returns from 1971-1999 and the MSCI United Kingdom Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'JPY',
          description: hasNewMstarIndices
            ? `The Morningstar Japan Net Total Return Index
          
The factor is designed be in excess of the local risk-free rate.`
            : "Japan's equity gross returns from 1971-1999 and the MSCI Japan Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'NOK',
          description: hasNewMstarIndices
            ? `The Morningstar Norway Net Total Return Index
            
The factor is designed be in excess of the local risk-free rate.`
            : "Norway's equity gross returns from 1971-1999 and the MSCI Norway Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'NZD',
          description: hasNewMstarIndices
            ? `The Morningstar New Zealand Net Total Return Index
            
The factor is designed be in excess of the local risk-free rate.`
            : "New Zealand's equity gross returns from 1971-1999 and the MSCI New Zealand Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'SEK',
          description: hasNewMstarIndices
            ? `The Morningstar Sweden Net Total Return Index 
          
The factor is designed be in excess of the local risk-free rate.`
            : "Sweden's equity gross returns from 1971-1999 and the MSCI Sweden Net Total Return Local Index from 2000.",
        },
        {
          title: 'Inputs',
          userOrgFilter: (org) => org?.currency === 'USD',
          description: hasNewMstarIndices
            ? `The Morningstar US Market Net Total Return Index 
          
The factor is designed be in excess of the local risk-free rate.`
            : "Ken French's U.S. Market Factor from 1971-1999 and the MSCI USA Net Total Return Local Index from 2000.",
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor is designed to be orthogonal to the Equity factor.'
            : 'The factor is designed to be orthogonal to the Equity factor and in excess of the local risk-free rate.',
        },
      ],
    },
    'equity-short-volatility': {
      name: 'Equity Short Volatility',
      category: 'Macro Styles',
      title:
        '**Equity Short Volatility** is a **Macro Styles Factor** that is designed to have negative exposure to the moves in equity market volatility.',
      aspects: [
        {
          title: 'Inputs',
          description: hasNewMstarIndices
            ? `The CBOE S&P 500 PutWrite Index 

The factor is designed be in excess of the risk-free rate.`
            : 'The CBOE S&P 500 PutWrite Index.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: hasNewMstarIndices
            ? 'The factor is designed to be orthogonal to the Equity factor.'
            : 'The factor is designed to be orthogonal to the Equity factor and in excess of the risk-free rate.',
        },
      ],
    },
    'fixed-income-carry': {
      name: 'Fixed Income Carry',
      category: 'Macro Styles',
      title:
        '**Fixed Income Carry** is a **Macro Styles Factor** that is designed to have exposure to high-yielding 10-year bond futures funded by low-yielding 10-year bond futures.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'Various carry metrics such as term spread for 10 year bond futures in the following 6 countries: United States, United Kingdom, Canada, Australia, Japan, and Europe.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: 'The factor is designed to be orthogonal to the Interest Rates factor.',
        },
      ],
    },
    'foreign-exchange-carry': {
      name: 'Foreign Exchange Carry',
      category: 'Macro Styles',
      title:
        '**Foreign Exchange Carry** is a **Macro Styles Factor** that is designed to have exposure to high-yielding G10 currencies funded by low-yielding G10 currencies.',
      aspects: [
        {
          title: 'Inputs',
          description: 'Interest rate differentials for G10 currencies.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description: 'The factor is designed to be orthogonal to the Equity factor.',
        },
      ],
    },
    'trend-following': {
      name: 'Trend Following',
      category: 'Macro Styles',
      title:
        '**Trend Following** is a **Macro Styles Factor** that is designed to have long-short exposure to multi-asset-class futures based on 6- to 12-month trailing returns.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A long-short portfolio using 6 and 12 month trailing returns trends for futures contracts across fixed income, commodities, equities, and currencies.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            "The factor does not undergo additional residualization, as it is intended to capture active managers' short- to medium-term trend-following behavior that can take on long or short directional risk in any given factor at different times. The factor is constructed to be market neutral over a market cycle, and generally exhibits low correlations to other factors over multi-year time frames.",
        },
      ],
    },
    'low-risk': {
      name: 'Low Risk',
      category: 'Equity Styles',
      title:
        '**Low Risk** is an **Equity Style Factor** that has long exposure to stocks with low market betas and residual volatility and short exposure to higher-risk stocks.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio of global stocks that is long those that exhibit positive Low Risk characteristics and short those that do not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
    momentum: {
      name: 'Momentum',
      category: 'Equity Styles',
      title:
        '**Momentum** is an **Equity Style Factor** that has long exposure to stocks that have outperformed recently and short exposure to recently underperforming stocks.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio of global stocks that is long those that exhibit a positive Momentum characteristic and short those that do not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be region neutral and equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
    quality: {
      name: 'Quality',
      category: 'Equity Styles',
      title:
        '**Quality** is an **Equity Style Factor** that has long exposure to stocks with low leverage and high profitability and short exposure to lower-quality stocks.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio of global stocks that is long those that exhibit positive Quality characteristics and short those that do not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be region neutral and equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
    value: {
      name: 'Value',
      category: 'Equity Styles',
      title:
        '**Value** is an **Equity Style Factor** that has long exposure to stocks with low prices relative to accounting fundamentals and short exposure to higher-priced stocks relative to fundamentals.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio of global stocks that is long those that exhibit positive Value characteristics and short those that do not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be region neutral and equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
    'small-cap': {
      name: 'Small Cap',
      category: 'Equity Styles',
      title:
        '**Small Cap** is an **Equity Style Factor** that has long exposure to stocks with smaller market caps and short exposure to larger-cap stocks.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio of global stocks that is long those that exhibit a positive Small Cap characteristic and short those that do not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be region neutral and equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
    crowding: {
      name: 'Crowding',
      category: 'Equity Styles',
      title:
        '**Crowding** is an **Equity Style Factor** that has short exposure to U.S. stocks that are widely shorted by the investment community and long exposure to those stocks that are not as widely held short.',
      aspects: [
        {
          title: 'Inputs',
          description:
            'A portfolio that is short U.S. stocks that are widely shorted by the investment community and long those that are not.',
        },
        {
          title: `[Residualization](${RESIDUALIZATION_ARTICLE_URL})`,
          description:
            'The equity style factors do not undergo additional residualization, as they are constructed to be region neutral and equity-market neutral and therefore generally exhibit low correlations to the other factors.',
        },
      ],
    },
  };
};
