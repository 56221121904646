import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import type { InvestmentForecast, CMAGrouping, CustomFactorForecast, CMAOption } from 'venn-api';
import type { DateRange } from 'venn-ui-kit';
import { Button as ButtonBase, Link as LinkStyle, Body1, Subtitle2 } from 'venn-ui-kit';
import EditableText from '../views/EditableText';
import CMARow from './CapitalMarketAssumptionRowV2';
import { FORECAST_INPUT_TEXT } from './shared';

interface CapitalMarketAssumptionsEditorV2Props {
  forecast?: CustomFactorForecast;
  cmaGroups: CMAGrouping[] | null;
  isLoadingCMAGroups: boolean;
  // Modal Footer Actions
  onCancel: () => void;

  onSubmitAssumptions: (
    assumptions: InvestmentForecast[],
    name: string,
    forecast?: CustomFactorForecast,
    historicalPeriod?: DateRange,
  ) => void;
}

// TODO(VENN-24534): add a display name to this React component
// eslint-disable-next-line react/display-name
export default ({
  forecast,
  cmaGroups,
  isLoadingCMAGroups,
  onCancel,
  onSubmitAssumptions,
}: CapitalMarketAssumptionsEditorV2Props) => {
  const [name, setName] = useState(forecast?.name ?? '');

  const [assumptions, setAssumptions] = useState<Partial<InvestmentForecast>[]>([
    {
      fundId: undefined,
      returnVal: undefined,
    },
  ]);

  useEffect(() => {
    if (forecast !== undefined) {
      setAssumptions(forecast.investmentForecasts);
    }
  }, [forecast]);

  const getGroup = (assumption: Partial<InvestmentForecast>) =>
    assumption.fundId === undefined
      ? undefined
      : (cmaGroups || []).find(
          (group: CMAGrouping) =>
            group.options.find((option: CMAOption) => option.id === assumption.fundId) !== undefined,
        );
  const getExcluded = (updatedAssumptions: Partial<InvestmentForecast>[]) => {
    const groupsToExclude: CMAGrouping[] = [];
    // @ts-expect-error: TODO fix strictFunctionTypes
    updatedAssumptions.forEach((assumption: InvestmentForecast) => {
      const group = getGroup(assumption);
      if (group !== undefined) {
        groupsToExclude.push(group);
      }
    });
    return groupsToExclude;
  };

  const onAddAssumption = useCallback(
    () => setAssumptions([...assumptions, { fundId: undefined, returnVal: undefined }]),
    [assumptions],
  );
  const onDeleteAssumption = useCallback(
    (idx: number) => {
      const updatedAssumptions = [...assumptions];
      updatedAssumptions.splice(idx, 1);
      setAssumptions(updatedAssumptions);
    },
    [assumptions],
  );
  const onUpdateAssumption = useCallback(
    (idx: number, assumption: Partial<InvestmentForecast>) => {
      const updatedAssumptions = [...assumptions];
      updatedAssumptions.splice(idx, 1, assumption);
      setAssumptions(updatedAssumptions);
    },
    [assumptions],
  );

  const investmentForecasts = useMemo(() => getInvestmentForecasts(assumptions), [assumptions]);

  const onSubmit = useCallback(
    () => onSubmitAssumptions(investmentForecasts, name, forecast),
    [forecast, name, investmentForecasts, onSubmitAssumptions],
  );

  return (
    <>
      <Subtitle2>Enter Forecast Name</Subtitle2>
      <NameEditorWrapper>
        <EditableText
          text={name}
          placeholder={FORECAST_INPUT_TEXT}
          displayEditViewByDefault
          allowEmptyStrings
          displayEditIconByDefault
          onSubmit={setName}
        />
      </NameEditorWrapper>
      <CMAListHeader>
        <Index>Index</Index>
        <Forecast>Forecast</Forecast>
      </CMAListHeader>

      <CMAList>
        {assumptions.map((assumption: Partial<InvestmentForecast>, idx: number) => (
          <CMARow
            // TODO
            // eslint-disable-next-line react/no-array-index-key
            key={idx.toString()}
            index={idx}
            assumption={assumption}
            onDelete={onDeleteAssumption}
            onChange={onUpdateAssumption}
            currentGroup={getGroup(assumption)}
            excludedGroups={getExcluded(assumptions)}
            allGroups={cmaGroups || []}
            isLoadingCMAGroups={isLoadingCMAGroups}
          />
        ))}
        <Body1>
          <LinkStyle onClick={onAddAssumption} className="qa-add-new-assumption">
            Add New Assumption
          </LinkStyle>
        </Body1>
      </CMAList>
      <Actions>
        <Button type="submit" dense dominant onClick={onSubmit} disabled={name === ''} tabIndex={0}>
          Apply
        </Button>
        <Button dense onClick={onCancel} tabIndex={0}>
          Cancel
        </Button>
      </Actions>
    </>
  );
};

const getInvestmentForecasts = (assumptions: Partial<InvestmentForecast>[]): InvestmentForecast[] =>
  assumptions
    .filter(
      (assumption: Partial<InvestmentForecast>) =>
        assumption.fundId !== undefined && assumption.returnVal !== undefined,
    )
    // @ts-expect-error: TODO fix strictFunctionTypes
    .map((assumption: InvestmentForecast) => assumption);

const CMAListHeader = styled(Body1)`
  width: 100%;
  height: 24px;
  min-height: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const NameEditorWrapper = styled.div`
  margin-left: 6px;
`;

const Button = styled(ButtonBase)`
  margin-right: 0;
  margin-left: 10px;
`;

const CMAList = styled.div`
  flex: 1;
`;

const Actions = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
`;

const Index = styled.div`
  flex-grow: 1;
`;

const Forecast = styled.div`
  width: 125px;
`;
